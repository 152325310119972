import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import breebs_screenshot from "@images/breebs_screenshot.png";

const OhmyplugLegal = () => {
  return (
    <section className="banner-one" id="conseil">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
            <h1>
                Introducing Breebs
            </h1>
                <h2>Power your chats with community knowledge pills, fresh and specialized
                </h2>
              
              <p>

              </p>
              <div>
              <h2>
              Visit <a href="https://www.breebs.com">www.breebs.com</a>
              </h2>
              <h2>
              Use the <a href="https://chat.openai.com/g/g-lObyD60FY-breebs">Breebs GPT</a>
              </h2>
              
                <iframe width="560" height="315" src="https://www.youtube.com/embed/5f63WljU7S4?autoplay=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
              
                
              
              </div>
              <p>

              </p>
              <h1>
                Breebs, OhMyPlug and Able Legal Information
                
              </h1>
              <br />
              <h2>
                Introduction
              </h2>
              <p>
                This legal disclaimer applies to the usage of "Breebs", "OhMyPlug" and "Able" Plugins and GPTs (hereinafter referred to as the "GPTs") hosted on promptbreeders.com. By using the GPTs, you accept and agree to be bound by the terms and conditions set forth in this legal disclaimer.
              </p>
              <h2>
                Purpose
              </h2>
              <p>
                The GPTs are designed to offer detailed insights, usage examples, ratings, instant access and expert advice. They are intended for general informational purposes only and should not be considered as a substitute for personal research, preferences, or professional advice.
              </p>

              <h2>
                Accuracy and Completeness
              </h2>
              <p>
                While we strive to provide accurate, up-to-date, and complete information, we make no warranties or representations regarding the accuracy, completeness, or reliability of the information provided by the GPTs. Users are encouraged to verify the information before making any decisions based on the GPTs' suggestions.
              </p>

              <h2>
                Limitation of Liability
              </h2>
              <p>
                To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of or inability to use the GPTs, even if we have been advised of the possibility of such damages.              
              </p>


              <h2>
                Third-Party Content and Links
              </h2>
              <p>
                The GPTs may suggest links to third-party websites or resources. We are not responsible for the content or availability of such websites or resources and do not endorse or assume any responsibility for any content, products, or services available on or through such websites or resources. Users acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, products, or services available on or through any such website or resource.
              </p>

              <h2>
                Changes to the Legal Disclaimer
              </h2>
              <p>
                We reserve the right to modify this legal disclaimer at any time without prior notice. Users are responsible for regularly reviewing this legal disclaimer to stay informed about any changes. Continued use of the GPTs after any modifications to this legal disclaimer constitutes acceptance of the revised terms and conditions.
              </p>

              <h2>
                Governing Law
              </h2>
              <p>
                This legal disclaimer shall be governed by and construed in accordance with the laws of the jurisdiction in which the GPTs' owner is located. Users agree to submit to the exclusive jurisdiction of the courts of that jurisdiction for the resolution of any disputes arising from or in connection with this legal disclaimer or the use of the GPTs.
              </p>

              <h2>
                Contact Information
              </h2>
              <p>
                For any questions, concerns, or comments regarding this legal disclaimer, please contact us at contact@promptbreeders.com
              </p>








            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OhmyplugLegal;
