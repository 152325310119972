import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";


import OhmyplugLegal from "@components/ohmyplug-legal";

import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";

const Legal = () => {
  return (
    <Layout pageTitle="Legal - OhMyPlug">
      <Header />
     
      <OhmyplugLegal />

      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Legal;
